import moment from "moment";
import { IListItem } from "./Application";

export interface IPalletLoadDetails {
	id?: string;

	// Depot Data
  depotId: string;
  depotName: string;

	// Worker Data
  workerId?: string;
  workerName?: string;

  // Trailer Data
  fleetNumber?: string;
  isTrailerClean?: boolean;
  trailerPalletCapacity?: number;
  fleetNumberManuallyEntered?: boolean;
  trailerPhotos?: string[];

  // Trailer Zone 1
  trailerZone1SetTemp?: number;
  trailerZone1ActualTemp?: number;

  // Trailer Zone 2
  trailerZone2SetTemp?: number;
  trailerZone2ActualTemp?: number;

  // Trailer Zone 3
  trailerZone3SetTemp?: number;
  trailerZone3ActualTemp?: number;

  // Stock Temperature History
  freezerStockTemp?: IStockTemp;
  chillerStockTemp?: IStockTemp;

  loadStartedOn: string;
  loadFinishedOn?: string;
  loadCancelledOn?: string;
	loadDeletedOn?: string;
  cancelReason?: string;
  deviceName: string;
  
  pallets: IPallet[];
  run: IPalletRun;
	dockNumber?: number;
}

export interface IPalletLoadListItem {
	id: string;
	fleetNumber: string;
	workerId: string;
	workerName: string;
	depotId: number;
	depotName: string;
	runNumber: string;
	loadStartedOn: string;
	deviceName: string;
	totalPallets: number;
	loadFinishedOn: string | null;
	loadCancelledOn: string | null;
	status: "INPROGRESS" | "FINISHED";
	isPaused: boolean;
	trailerPalletCapacity: number;
	dockNumber?: number;
}

export interface IPallet {
	palletId: string;
	loadedOn: string;
	manuallyEntered: boolean;
	palletType: string;
	isDamaged: boolean;
	isUpstacked: boolean;
	locationInTrailer: string;
	commodity: string;
	commodityManuallyEntered: boolean;
	store: IPalletStore;
	loadedBy: IListItem;
	cartons?: number;
	weight?: number;
	damagePhotos?: string[];
	isUpstackedWith?: IPallet[];
	wasPalletTimberKept?: boolean;

	// NonDB - UI Only
	isDeleted?: boolean;
}

export class PalletLoadListFilter {
	startTimeLocal = moment().add(-7, 'day').format('YYYY-MM-DD 00:00');
	finishTimeLocal = moment().format('YYYY-MM-DD 23:59');
	status: '' | 'INPROGRESS' | 'FINISHED' | 'CANCELLED' = '';
	fleetNumber?: string;
	depotId?: string;
	workerId?: string;
	runNumber?: string;
}

export class PalletLoadDetailsForm implements Omit<IPalletLoadDetails, 'trailerPalletCapacity' | 'depotId' | 'dockNumber'> {
	id?: string;

	// Depot Data
  depotId: string;
  depotName: string;

	// Worker Data
  workerId: string;
  workerName: string;

  // Trailer Data
  fleetNumber: string;
  isTrailerClean?: boolean;
  trailerPalletCapacity: string;
  fleetNumberManuallyEntered: boolean;
  trailerPhotos?: string[];

  // Trailer Zone 1
  trailerZone1SetTemp?: number;
  trailerZone1ActualTemp?: number;

  // Trailer Zone 2
  trailerZone2SetTemp?: number;
  trailerZone2ActualTemp?: number;

  // Trailer Zone 3
  trailerZone3SetTemp?: number;
  trailerZone3ActualTemp?: number;

  // Stock Temperature History
  freezerStockTemp?: IStockTemp;
  chillerStockTemp?: IStockTemp;

  loadStartedOn: string;
  loadFinishedOn?: string;
  loadCancelledOn?: string;
	loadDeletedOn?: string;
  cancelReason?: string;
  deviceName: string;
  
  pallets: IPallet[];
  run: IPalletRun;
	dockNumber: string;

	constructor(details?: IPalletLoadDetails) {
		this.id = details?.id;

		// Depot Data
		this.depotId = details?.depotId || '';
		this.depotName = details?.depotName || '';

		// Worker Data
		this.workerId = details?.workerId || '';
		this.workerName = details?.workerName || '';

		// Trailer Data
		this.fleetNumber = details?.fleetNumber || '';
		this.isTrailerClean = details?.isTrailerClean;
		this.trailerPalletCapacity = details?.trailerPalletCapacity?.toString() || '';
		this.fleetNumberManuallyEntered = details?.fleetNumberManuallyEntered || false;
		this.trailerPhotos = details?.trailerPhotos;

		// Trailer Zone 1
		this.trailerZone1SetTemp = details?.trailerZone1SetTemp;
		this.trailerZone1ActualTemp = details?.trailerZone1ActualTemp;

		// Trailer Zone 2
		this.trailerZone2SetTemp = details?.trailerZone2SetTemp;
		this.trailerZone2ActualTemp = details?.trailerZone2ActualTemp;

		// Trailer Zone 3
		this.trailerZone3SetTemp = details?.trailerZone3SetTemp;
		this.trailerZone3ActualTemp = details?.trailerZone3ActualTemp;

		// Stock Temperature History
		this.freezerStockTemp = details?.freezerStockTemp;
		this.chillerStockTemp = details?.chillerStockTemp;

		this.loadStartedOn = details?.loadStartedOn ? moment(details?.loadStartedOn).toISOString() : '';
		this.loadFinishedOn = details?.loadFinishedOn ? moment(details?.loadFinishedOn).toISOString() : '';
		this.loadCancelledOn = details?.loadCancelledOn ? moment(details?.loadCancelledOn).toISOString() : '';
		this.loadDeletedOn = details?.loadDeletedOn ? moment(details?.loadDeletedOn).toISOString() : '';
		this.cancelReason = details?.cancelReason;
		this.deviceName = details?.deviceName || '';
		
		this.pallets = details?.pallets || [];
		this.run = details?.run || { id: '', name: '' };
		this.dockNumber = details?.dockNumber?.toString() || '';
	}
}

export class PalletDetailsForm {
	palletId: string;
	loadedOn: string;
	manuallyEntered: boolean;
	palletType: string;
	isDamaged: boolean;
	isUpstacked: boolean;
	locationInTrailer: string;
	commodity: string;
	commodityManuallyEntered: boolean;
	cartons?: number;
	weight?: number;
	store: IPalletStore;
	damagePhotos?: string[];
	isUpstackedWith?: IPallet[];
	wasPalletTimberKept?: boolean
	loadedBy: IListItem;

	constructor(pallet: IPallet) {
		this.palletId = pallet.palletId;
		this.loadedOn = pallet.loadedOn;
		this.manuallyEntered = pallet.manuallyEntered;
		this.palletType = pallet.palletType;
		this.isDamaged = pallet.isDamaged;
		this.isUpstacked = pallet.isUpstacked;
		this.locationInTrailer = pallet.locationInTrailer;
		this.commodity = pallet.commodity;
		this.commodityManuallyEntered = pallet.commodityManuallyEntered;
		this.cartons = pallet.cartons;
		this.weight = pallet.weight;
		this.store = pallet.store;
		this.damagePhotos = pallet.damagePhotos;
		this.isUpstackedWith = pallet.isUpstackedWith;
		this.wasPalletTimberKept = pallet.wasPalletTimberKept;
		this.loadedBy = pallet.loadedBy;
	}
}

export class PalletLoad_PUT {
		id: string;

    // Depot Data
    depotId: number;
    depotName: string;

    // Worker Data
    workerId: string;
    workerName: string;

    // Trailer Data
    fleetNumber: string;
    isTrailerClean?: boolean;
    trailerPalletCapacity: number;
    fleetNumberManuallyEntered?: boolean;
    trailerPhotos?: string[];

    // Trailer Zone 1
    trailerZone1SetTemp?: number;
    trailerZone1ActualTemp?: number;

    // Trailer Zone 2
    trailerZone2SetTemp?: number;
    trailerZone2ActualTemp?: number;

    // Trailer Zone 3
    trailerZone3SetTemp?: number;
    trailerZone3ActualTemp?: number;

    // Stock Temperature
    freezerStockTemp?: IStockTemp;
    chillerStockTemp?: IStockTemp;

    loadStartedOn: string;
    loadFinishedOn?: string;
    loadDeletedOn?: string;
    loadCancelledOn?: string;
    cancelReason?: string;

    deviceName: string;
    pallets: PalletPUT[];
    run: IPalletRun;
		dockNumber: number;

  constructor (load: PalletLoadDetailsForm) {
    this.id = load.id!;
    this.depotId = parseInt(load.depotId);
    this.depotName = load.depotName;
    this.workerId = load.workerId;
    this.workerName = load.workerName;
    this.loadStartedOn = load.loadStartedOn;
    this.loadFinishedOn = load.loadFinishedOn || undefined;

    // Trailer Data
    this.fleetNumber = load.fleetNumber; 
    this.isTrailerClean = load.isTrailerClean;
		this.trailerPalletCapacity = parseInt(load.trailerPalletCapacity);

    // Stock Temperature History (only if temp has value)
    this.freezerStockTemp = load.freezerStockTemp || undefined;
    this.chillerStockTemp = load.chillerStockTemp || undefined;

    // Trailer Zone 1
    this.trailerZone1SetTemp = load.trailerZone1SetTemp || undefined
    this.trailerZone1ActualTemp = load.trailerZone1ActualTemp || undefined;

    // Trailer Zone 2
    this.trailerZone2SetTemp = load.trailerZone2SetTemp || undefined;
    this.trailerZone2ActualTemp = load.trailerZone2ActualTemp || undefined;

    // Trailer Zone 3
    this.trailerZone3SetTemp = load.trailerZone3SetTemp || undefined;
    this.trailerZone3ActualTemp = load.trailerZone3ActualTemp || undefined;

    this.trailerPhotos = load.trailerPhotos;
    this.fleetNumberManuallyEntered = load.fleetNumberManuallyEntered;
    this.cancelReason = load.cancelReason || undefined;
    this.loadCancelledOn = load.loadCancelledOn || undefined;

		this.deviceName = load.deviceName;
    this.pallets = load.pallets.filter(pallet => !pallet.isDeleted).map(pallet => new PalletPUT(pallet));
		this.run = load.run;
		this.dockNumber = parseInt(load.dockNumber);
	}
}	

class PalletPUT {
	palletId: string;
	loadedOn: string;
	manuallyEntered: boolean;
	palletType: string;
	isDamaged?: boolean;
	isUpstacked?: boolean;
	locationInTrailer: string;
	commodity: string;
	commodityManuallyEntered: boolean;
	cartons?: number;
	weight?: number;
	store: IPalletStore;
	damagePhotos?: string[];
	isUpstackedWith?: PalletPUT[];
	wasPalletTimberKept: boolean;
	loadedBy: IListItem;

	constructor(pallet: PalletDetailsForm) {
		console.log(pallet.isUpstackedWith)
		this.palletId = pallet.palletId;
		this.palletType = pallet.palletType;
		this.locationInTrailer = pallet.locationInTrailer;
		this.commodity = pallet.commodity;
		this.store = pallet.store;
		this.loadedOn = pallet.loadedOn;
		this.manuallyEntered = pallet.manuallyEntered || false;
		this.isDamaged = pallet.isDamaged || false;
		this.isUpstacked = pallet.isUpstacked || false;
		this.commodityManuallyEntered = pallet.commodityManuallyEntered || false;
		this.cartons = pallet.cartons || undefined;
		this.weight = pallet.weight || undefined;
		this.damagePhotos = pallet.damagePhotos || undefined;
		this.isUpstackedWith = pallet.isUpstackedWith?.filter(pallet => !pallet.isDeleted).map(pallet => new PalletPUT(pallet));
		this.wasPalletTimberKept = pallet.wasPalletTimberKept || false;
		this.loadedBy = pallet.loadedBy;
	}
}


export interface IPalletRun {
	id: string;
	name: string;
}

export interface IPalletStore {
	id: number;
	name: string;
	storeNumber: string;
}

export interface IStockTemp {
	temp: number;
	collectedAt: Date;
}