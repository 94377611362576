import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { ITaskListItem, TasksListFilter } from '../../../../app/models/Task';
import { HoursRange } from '../../../../app/models/Application';
import { ITaskDetail, TaskForm } from '../../../../app/models/Task';

import { extendMoment } from 'moment-range';
import { TaskUpdateReasonCategory, TaskUpdateReasonForm } from '../../../../app/models/Task';
import { IVehicleMaintenance, IVehicleMaintenanceListDetails } from '../../../../app/models/VehicleMaintenance';
import { IVehicleDetailsForTask, VehicleTypes } from '../../../../app/models/Vehicle';

const Moment = require('moment');
const moment = extendMoment(Moment);

export function filterTasksList(tasksList: ITaskListItem[], listFilter: TasksListFilter, startingDepotId?: string, date?: string) {
	const { workerId, workerRoleId, hoursRange, workerCurrentCompanyAbn } = listFilter;

	if (date)
		tasksList = tasksList
		.filter(p => moment(p.startTimeLocal).isSameOrAfter(moment(`${date} 00:00`)) && 
		moment(p.startTimeLocal).isSameOrBefore(moment(`${date} 23:59`)));

	if (hoursRange) {
		const [startHour, finishHour] = hoursRange.split('-');
		tasksList = tasksList
			.filter(p => moment(p.startTimeLocal).isSameOrAfter(moment(`${moment(p.startTimeLocal).format('YYYY-MM-DD')} ${startHour}`)) && 
			moment(p.startTimeLocal).isSameOrBefore(moment(`${moment(p.startTimeLocal).format('YYYY-MM-DD')} ${finishHour}`)));
	}

	if (startingDepotId)
		tasksList = filter(tasksList, p => parseInt(startingDepotId) === p.startingDepotId);

	if (workerId)
		tasksList = filter(tasksList, ['workerId', workerId]);
	
	if (workerRoleId)
		tasksList = filter(tasksList, p => parseInt(workerRoleId) === p.Worker?.currentRoleId);

	if (workerCurrentCompanyAbn)
		tasksList = filter(tasksList, p => p.Worker?.currentCompanyId === workerCurrentCompanyAbn);

	return orderBy(tasksList, [a => moment(a.startTimeLocal), 'Worker.shortName']);
}

export function totalTasksByHoursRange(tasksList: ITaskDetail[], hoursRange: HoursRange, startingDepotId?: string) {
	const [startHour, finishHour] = hoursRange.split('-');
	return tasksList.filter(p => !p.isCancelled && p.startingDepotId?.toString() === startingDepotId && moment(p.startTimeLocal).isSameOrAfter(moment(`${moment(p.startTimeLocal).format('YYYY-MM-DD')} ${startHour}`)) && 
		moment(p.startTimeLocal).isSameOrBefore(moment(`${moment(p.startTimeLocal).format('YYYY-MM-DD')} ${finishHour}`))).length;
}

export function getVehicleServiceOverlappingTask(taskStartTime: string, taskBudgetedTime: number, vehicleServicesList: IVehicleMaintenance[]): IVehicleMaintenanceListDetails | undefined {
	const newTaskRange = moment.range([moment(taskStartTime), moment(taskStartTime).add(taskBudgetedTime, 'hours')]);
	return vehicleServicesList.find(vehicleMaintenance => {
		const { startTimeLocal, finishTimeLocal } = vehicleMaintenance;
		const vehicleMaintenanceRange = moment.range([moment(startTimeLocal), moment(finishTimeLocal)]);
		return vehicleMaintenanceRange.intersect(newTaskRange);
	});
}

/**
 * Returns the update reasons that should be completed based on the changes on the task
 * @param originalTask Task before changes
 * @param updatedTask  Task after changes
 */
export function getTaskUpdateReasonCategories(originalTask: TaskForm, updatedTask: TaskForm): TaskUpdateReasonForm[] {
	const updateReasonCategories: TaskUpdateReasonForm[] = [];

	// TASK CANCELLED / DELETED
	// If it's cancelled it will only return the cancelled reason as any other change won't be made
	if (originalTask.isCancelled !== updatedTask.isCancelled) {
		const updateReasonFormObject = new TaskUpdateReasonForm(TaskUpdateReasonCategory.TASK_CANCELLED_OR_DELETED);
		updateReasonCategories.push(updateReasonFormObject);
		return updateReasonCategories;
	}

	// WORKER CHANGED
	// Only applies if there was another worker assigned before
	if (originalTask.workerId && originalTask.workerId !== updatedTask.workerId) {
		const updateReasonFormObject = new TaskUpdateReasonForm(TaskUpdateReasonCategory.WORKER, originalTask.Worker?.shortName, updatedTask.Worker?.shortName);
		updateReasonCategories.push(updateReasonFormObject);
	}

	// START TIME CHANGED
	// Only applies if the startTime has a valid value and don't need to be adjusted
	if (originalTask.startTime && !originalTask.isStartTimeAdjustNeeded && originalTask.startTime !== updatedTask.startTime) {
		const updateReasonFormObject = new TaskUpdateReasonForm(TaskUpdateReasonCategory.START_TIME, originalTask.startTime, updatedTask.startTime);
		updateReasonCategories.push(updateReasonFormObject);
	}

	// BUDGETED TIME CHANGED
	// Only applies if there was a budgeted time and the start time didn't need to be adjusted
	if (originalTask.budgetedTime && !originalTask.isStartTimeAdjustNeeded && originalTask.budgetedTime !== '0' && originalTask.budgetedTime !== updatedTask.budgetedTime) {
		const updateReasonFormObject = new TaskUpdateReasonForm(TaskUpdateReasonCategory.BUDGETED_TIME, originalTask.budgetedTime, updatedTask.budgetedTime);
		updateReasonCategories.push(updateReasonFormObject);
	}

	// VEHICLES CHANGED
	// Only applies if there was another vehicle before
	if (
		(originalTask.mainVehFleetNumber && originalTask.mainVehFleetNumber !== updatedTask.mainVehFleetNumber) ||
		(originalTask.trailer1FleetNumber && originalTask.trailer1FleetNumber !== updatedTask.trailer1FleetNumber) ||
		(originalTask.trailer2FleetNumber && originalTask.trailer2FleetNumber !== updatedTask.trailer2FleetNumber)
	) {
		const oldVehicles = [originalTask.mainVehFleetNumber, originalTask.trailer1FleetNumber, originalTask.trailer2FleetNumber].filter(vehicle => vehicle).join(', ');
		const newVehicles = [updatedTask.mainVehFleetNumber, updatedTask.trailer1FleetNumber, updatedTask.trailer2FleetNumber].filter(vehicle => vehicle).join(', ');
		const updateReasonFormObject = new TaskUpdateReasonForm(TaskUpdateReasonCategory.VEHICLE, oldVehicles, newVehicles);

		updateReasonCategories.push(updateReasonFormObject);
	}

	// BUDGETED KM CHANGED
	// Only applies if there was a budgeted km before updating
	if (originalTask.budgetedKm && originalTask.budgetedKm !== '0' && originalTask.budgetedKm !== updatedTask.budgetedKm) {
		const updateReasonFormObject = new TaskUpdateReasonForm(TaskUpdateReasonCategory.BUDGETED_KM, originalTask.budgetedKm, updatedTask.budgetedKm);
		updateReasonCategories.push(updateReasonFormObject);
	}

	// STORE MAX PALLET CAPACITY EXCEEDED
	if (
		// Only applies if there is a vehicle allocated and that haven't been reported with an issue
		(updatedTask.mainVehFleetNumber || updatedTask.trailer1FleetNumber || updatedTask.trailer2FleetNumber)
		&& (
			// and if they are not the same vehicles as before
			(updateReasonCategories.findIndex(u => parseInt(u.updateReasonCategoryId) === TaskUpdateReasonCategory.VEHICLE) !== -1)
			// or if the issue hans't been raised yet
			|| originalTask.storePalletCapacityExceeded === false
		)
	) {
		const storesWithMaxPalletCapacitySet = updatedTask.RunCustomerOrders?.filter(store => store.storeMaxPalletCapacity)
		
		if (storesWithMaxPalletCapacitySet && storesWithMaxPalletCapacitySet.length > 0) {
			const storesWithMaxPalletCapacityExceeded = [];
			let vehicleExceededStoreMaxPalletCapacity;
			for (let i = 0; i < storesWithMaxPalletCapacitySet.length; i++) {
				const store = storesWithMaxPalletCapacitySet[i];
				if (!vehicleExceededStoreMaxPalletCapacity)
					vehicleExceededStoreMaxPalletCapacity = getVehicleExceedsStoreMaxPalletCapacity(store.storeMaxPalletCapacity, updatedTask.MainVehicle, updatedTask.Trailer1, updatedTask.Trailer2)

				if (vehicleExceededStoreMaxPalletCapacity)
					storesWithMaxPalletCapacityExceeded.push(store);
			}			

			if (vehicleExceededStoreMaxPalletCapacity && storesWithMaxPalletCapacityExceeded.length > 0) {
				const { fleetNumber } = vehicleExceededStoreMaxPalletCapacity;
				const stores = storesWithMaxPalletCapacityExceeded.map(store => `'${store.storeNumber} - ${store.placeName} (${store.storeMaxPalletCapacity} P)'`).join(', ');
				const newValueText = `Fleet No. '${fleetNumber}' exceeds the Max Pallet Capacity of the stores: ${stores}`;
				updateReasonCategories.push(new TaskUpdateReasonForm(TaskUpdateReasonCategory.MAX_STORE_PALLET_CAPACITY_EXCEEDED, 'OK', newValueText));
			}
		}
	}

	return updateReasonCategories;
}

/**
 * Returns the correct label for the update reason form based on what was changed in the task
 */
export function getTaskUpdateReasonLabel(updateReasonCategoryId: number) {
	switch (updateReasonCategoryId) {
		case TaskUpdateReasonCategory.TASK_CANCELLED_OR_DELETED:
			return 'Reason for Cancelling this task';
		case TaskUpdateReasonCategory.WORKER:
			return 'Reason for changing the Assigned Employee';
		case TaskUpdateReasonCategory.START_TIME:
			return 'Reason for changing the Start Time';
		case TaskUpdateReasonCategory.VEHICLE:
			return 'Reason for changing the Vehicle(s)';
		case TaskUpdateReasonCategory.BUDGETED_TIME:
			return 'Reason for changing the Budgeted Time';
		case TaskUpdateReasonCategory.BUDGETED_KM:
			return 'Reason for changing the Budgeted Kms';
		case TaskUpdateReasonCategory.MAX_STORE_PALLET_CAPACITY_EXCEEDED:
			return 'Reason for allocating a truck that exceeds the maximum pallet capacity of the store';
		default:
			return 'Reason for this change';
	}
}

/**
 * * Checks if the employee has any overlapping tasks
 * @param tasksList List with the tasks to be checked
 * @param workerId The employee's ID
 * @param newTaskStartDateTime The start time of the new/updated task
 * @param newTaskBudgetedTime The budgeted time of the new/updated task
 * @param taskId The task ID if it's not a new one
 */
export function checkTaskOverlappingOtherTasks(tasksList: ITaskDetail[], workerId: string, newTaskStartDateTime: string, newTaskBudgetedTime: number, taskId?: number): ITaskDetail | undefined {
	// New Task
	const newTaskFinishDateTime = moment(newTaskStartDateTime).add(newTaskBudgetedTime, 'hours');
	const newTaskRange = moment.range([moment(newTaskStartDateTime), moment(newTaskFinishDateTime)]);

	return tasksList.filter(p => workerId && p.id !== taskId && !p.isCancelled && p.Worker?.azureId === workerId).find(task => {
		const { startTimeLocal, budgetedTime, actualFinishTimeLocal } = task;

		const $taskStartTime = moment(startTimeLocal);
		const $actualFinishTime = actualFinishTimeLocal ? moment(actualFinishTimeLocal) : undefined;

		// Task from List
		// If the actual finish time is greater than 18 hours it means the driver forgot to do the
		// last part of the pre-start, which means it should use the budgeted time instead of the actual
		const $taskFinishTime = $actualFinishTime && moment.duration($actualFinishTime.diff($taskStartTime)).asHours() < 17 ? $actualFinishTime : moment($taskStartTime).add(budgetedTime, 'hours');
		const taskRange = moment.range([$taskStartTime, $taskFinishTime]);

		return newTaskRange.intersect(taskRange);
	});
}

/**
 * Checks if the vehicle is already in use in another task
 * @param tasksList List with the tasks to be checked
 * @param fleetNumber The vehicle's fleet number
 * @param newTaskStartDateTime The start time of the new/updated task
 * @param newTaskBudgetedTime The budgeted time of the new/updated task
 * @param task Task data
 */
export function checkVehicleAlreadyInUse(tasksList: ITaskDetail[], fleetNumber: string, newTaskStartDateTime: string, newTaskBudgetedTime: number, task: ITaskDetail | TaskForm): ITaskDetail | undefined {
	if (!isDriverTask(task))
		return;

	// New Task
	const newTaskFinishDateTime = moment(newTaskStartDateTime).add(newTaskBudgetedTime, 'hours');
	const newTaskRange = moment.range([moment(newTaskStartDateTime), moment(newTaskFinishDateTime)]);

	return tasksList.filter(p => p.id !== task?.id && !p.isCancelled && isDriverTask(p)).find(task => {
		const { startTimeLocal, budgetedTime, actualFinishTimeLocal } = task;
		
		const $taskStartTime = moment(startTimeLocal);
		const $actualFinishTime = actualFinishTimeLocal ? moment(actualFinishTimeLocal) : undefined;

		// Task from List
		const $taskFinishTime = $actualFinishTime && moment.duration($actualFinishTime.diff($taskStartTime)).asHours() < 17 ? $actualFinishTime : moment($taskStartTime).add(budgetedTime, 'hours');
		const taskRange = moment.range([$taskStartTime, $taskFinishTime]);
		
		return (
			newTaskRange.intersect(taskRange) && (
				task.MainVehicle?.fleetNumber === fleetNumber
				|| task.Trailer1?.fleetNumber === fleetNumber
				|| task.Trailer2?.fleetNumber === fleetNumber
			)
		)
	});
}

export function isDriverTask(task: ITaskDetail | TaskForm) {
	return task.TaskType?.isRunRequired && task.TaskType?.isDriversLicenceRequired;
}

/**
 * Return the status of the task (not started, in progress, completed and cancelled)
 * @param taskDetails Task Details
 */
export function getTaskStatus(taskDetails: ITaskDetail | TaskForm): { text: string, className?: string, colorClassName?: string } {
	const { isCancelled, TaskType, actualFinishTimeLocal, actualStartTimeLocal, 
		// totalPalletsAU_actuals, totalPalletsT3_actuals 
	} = taskDetails;
	const currentTime = moment();
	const startTime = actualStartTimeLocal && moment(actualStartTimeLocal);

	if (isCancelled) 
		return {
			text: 'Cancelled',
			// className: 'cancelled'
		}

	if (!TaskType?.isDriversLicenceRequired)
		return { text: 'N/A' }
	
	if (actualFinishTimeLocal)
		return {
			text: 'Completed',
			className: 'completed',
			colorClassName: 'text-success'
		}

	// if the the actuals of the pallets have data, it means it is being picked by AMC
	// if (totalPalletsAU_actuals || totalPalletsT3_actuals) {
	// 	return {
	// 		text: 'Picking',
	// 		className: 'warning',
	// 		colorClassName: 'text-warning'
	// 	}
	// }

	if (actualStartTimeLocal)
		if (moment.duration(currentTime.diff(startTime)).asHours() > 18) // If run started but the pre-start wasn't completed
			return { text: 'Unknown' }
		else
			return {
				text: 'In Progress',
				className: 'in-progress',
				colorClassName: 'text-primary'
			}
			

	return { text: 'Not Started' }
		
}

/**
 * Check if the task has a proper fleet allocated to it
 * @param task Task Details
 * @returns 
 */
export function checkRightFleetAllocated(task: ITaskDetail) {
	if (!task.MainVehicle)
		return false;

	if (task.MainVehicle.typeId === VehicleTypes.PRIME_MOVER && !task.Trailer1)
		return false;

	return true;
}
/**
 * Get the total overtime hours if any
 * @param rosterStartTime Worker's shift start time
 * @param rosterBudgetedTime Worker's budgeted time (ordinary hours)
 * @param taskStartTime Task's start time
 * @param taskBudgetedTime Task's budgeted time
 * @returns 
 */
export function getTotalHoursOvertime(rosterStartTime: string, rosterBudgetedTime: number, taskStartTime: string, taskBudgetedTime: number) {
	const $workerShiftFinishTime = moment(rosterStartTime).add(rosterBudgetedTime, 'hours');
	const $taskEstimatedFinishTime = moment(taskStartTime).add(taskBudgetedTime, 'hours');
	const totalHoursOvertime = moment.duration($taskEstimatedFinishTime.diff($workerShiftFinishTime)).asHours();
	return totalHoursOvertime > 0 ? parseFloat(totalHoursOvertime.toFixed(1)) : 0;
}



/**
 * Checks if a vehicle exceeds the maximum pallet capacity of a store.
 * 
 * @param storeMaxPalletCapacity - The maximum pallet capacity of the store.
 * @param mainVehicle - The main vehicle details.
 * @param trailer1 - The details of trailer 1.
 * @param trailer2 - The details of trailer 2.
 * @returns The vehicle (main vehicle, trailer 1, or trailer 2) that exceeds the store's maximum pallet capacity, or undefined if none of the vehicles exceed the capacity.
 */
export function getVehicleExceedsStoreMaxPalletCapacity(storeMaxPalletCapacity?: number, mainVehicle?: IVehicleDetailsForTask, trailer1?: IVehicleDetailsForTask, trailer2?: IVehicleDetailsForTask) {
	// if the store doesn't have the maxPalletsCapacity set, it means it doesn't have any restriction
	// Or if there's no vehicle allocated, just return undefined
	if ((!mainVehicle && !trailer1 && !trailer2) || !storeMaxPalletCapacity) 
		return undefined;

	// If there's a Main Vehicle and it has a maxPallets set, check if the pallets capacity is greater than the store's max
	if (mainVehicle?.maxPallets && mainVehicle?.maxPallets > storeMaxPalletCapacity)
		return mainVehicle;

	// If there's a Trailer 1, and it has a maxPallets set, check if the pallets capacity is greater than the store's max
	if (trailer1?.maxPallets && trailer1?.maxPallets > storeMaxPalletCapacity)
		return trailer1;

	// If there's a Trailer 2, and it has a maxPallets set, check if the pallets capacity is greater than the store's max
	if (trailer2?.maxPallets && trailer2?.maxPallets > storeMaxPalletCapacity)
		return trailer2;

	return undefined;
}

/**
 * Checks if store requires a tailgate and if the vehicles doesn't have it.
 * 
 * (Required for specific Stores that have `isTailgateRequired` set to true)
 * 
 * @param storeRequiresTailgate - If the store requires a tailgate
 * @param mainVehicle - Main assigned vehicle
 * @param trailer1 - First tied trailer
 * @param trailer2 - Second tied trailer
 * @returns 
 */
export function getStoreRequiresTailgateIssue(storeRequiresTailgate?: boolean, mainVehicle?: IVehicleDetailsForTask, trailer1?: IVehicleDetailsForTask, trailer2?: IVehicleDetailsForTask) {
	// If the store doesn't require tailgate, no warning is needed
	if (!storeRequiresTailgate)
		return false;


	// If Main Vehicle is a Rigid and doesn't have a tailgate, return true to show warning
	if (mainVehicle?.typeId === VehicleTypes.RIGID && !mainVehicle.hasTailgate)
		return true;

	// If Trailer 1 is set and doesn't have a tailgate, return true to show warning
	if (trailer1 && !trailer1.hasTailgate)
		return true;

	// If Trailer 2 is set and doesn't have a tailgate, return true to show warning
	if (trailer2 && !trailer2.hasTailgate)
		return true;

	return false;
}