import { ITaskType } from "../Task";
import { IVehicleBasicInfo } from "../Vehicle";
import { IWorkerBasicInfo } from "../Worker";

export interface IRunBasicInfo {
	id: number;
	name: string;
	area: string;
}

export interface IRun {
	id: number;
	startTimeLocal: string;
	finishTimeLocal: string;
	startingDepotId: number;
	startingDepotName: string;
	customerLocation: string;
	customer: string;
	runNumberCombined: string;
	area: string;
	budgetedTime: number;
	isPreLoaded: boolean;
	budgetedKm?: number;
	mainVehFleetNumber?: string;
	trailer1FleetNumber?: string;
	trailer2FleetNumber?: string;
	isMainVehicleNotTracked: boolean;
	isTrailer1NotTracked: boolean;
	isTrailer2NotTracked: boolean;
	RunCustomers: IRunCustomer[];
	MainVehicle?: IVehicleBasicInfo;
	Trailer1?: IVehicleBasicInfo;
	Trailer2?: IVehicleBasicInfo;
	Worker: IWorkerBasicInfo;
	TaskType: ITaskType;
}

export interface IRunCustomer {
	id?: number;
	runNumber?: string;
	runTypeId?: number;
	runTypeName?: string;
	runTypeAcronym?: string;
}

export class RunCustomerForm {
	id?: number;
	runNumber: string;
	runTypeId: string;
	runTypeName?: string;
	runTypeAcronym?: string;

	constructor(runCustomer?: IRunCustomer) {
		this.id = runCustomer?.id;
		this.runNumber = runCustomer?.runNumber || '';
		this.runTypeId = runCustomer?.runTypeId?.toString() || '';
		this.runTypeName = runCustomer?.runTypeName || '';
		this.runTypeAcronym = runCustomer?.runTypeAcronym || '';
	}
}

export class RunCustomerSimple {
	runNumber: string = '';
	runTypeId: string = '';
}

export class RunCustomerPOST_PUT {
	id?: number;
	runTypeId: number;
	runNumber: string;

	constructor(runCustomer: RunCustomerForm) {
		this.id = runCustomer.id;
		this.runTypeId = parseInt(runCustomer.runTypeId);
		this.runNumber = runCustomer.runNumber;
	}
}

// export interface IRun {
// 	id: string;
// 	date: string;
// 	number: number;
// 	startingDepotId: number;
// 	budgetedKmsInMetres: number;
// 	budgetedRunTimeInMin: number;
// 	isPreLoaded: boolean;
// 	mainFleetNumber: string;
// 	trailer1FleetNumber?: string
// 	trailer2FleetNumber?: string
// 	startOdometerKm?: number;
// 	finishOdometerKm?: number;
// 	totalWeight?: number;
// 	departureTime?: Date;
// 	arrivalTime?: Date;
// 	actualTimeSpent?: number;
// 	actualKmsTravelledInMetres?: number;
// }

export class RunFormClass {
	id?: number;
	date?: string;
	number?: number;
	startingDepotId?: number;
	startingDepotName?: string;
	startOdometerKm?: number;
	finishOdometerKm?: number;
	budgetedKmsInMetres?: number;
	budgetedRunTimeInMin?: number;
	isPreLoaded?: boolean;
	mainFleetNumber?: string;
	trailer1FleetNumber?: string;
	trailer2FleetNumber?: string;
	isDriverNotTracked = false;
	isMainVehicleNotTracked = false;
	isTrailer1NotTracked = false;
	isTrailer2NotTracked = false;
	RunCustomerOrders?: IRunCustomerOrderOld[] = [];
}

export interface IRunDetailsForm {
	date: string;
	number: number;
	startingDepotId: number;
	startingDepotName?: string;
	startOdometerKm?: number;
	finishOdometerKm?: number;
	budgetedKmsInMetres: number;
	budgetedRunTimeInMin: number;
	isPreLoaded: boolean;
	mainFleetNumber: string;
	trailer1FleetNumber?: string
	trailer2FleetNumber?: string
	isDriverNotTracked: boolean;
	isMainVehicleNotTracked: boolean;
	isTrailer1NotTracked: boolean;
	isTrailer2NotTracked: boolean;
	totalWeight?: number;
	departureTime?: Date;
	arrivalTime?: Date;
	actualTimeSpent?: number;
	actualKmsTravelledInMetres?: number;
}

export interface IRunCustomerOrder {
	id?: number;
	deliverySequence: number;
	placeId: number;
	placeName?: string;
	storeNumber?: string;
	storeMaxPalletCapacity?: number;
	customerTypeId?: number;
	customerTypeName?: string;
	isCancelled?: boolean;
	storeRequiresTailgate?: boolean;

	storeNotificationSentOnLocal?: string;
	storeArrivalTimeLocal?: string;
	storeDepartureTimeLocal?: string;

	doorOpenTimeLocal?: string;
	doorOpenTempZone1?: number;
	doorOpenTempZone2?: number;
	doorOpenTempZone3?: number;
	
	doorCloseTimeLocal?: string;
	doorCloseTempZone1?: number;
	doorCloseTempZone2?: number;
	doorCloseTempZone3?: number;

	openWindow?: Date;
	closeWindow?: Date;
}

export class RunCustomerOrderForm {
	id?: number;
	deliverySequence: number;
	placeId: string;
	placeName?: string;
	storeNumber?: string;
	storeMaxPalletCapacity?: number;
	storeRequiresTailgate?: boolean;

	storeNotificationSentOnLocal?: string;
	storeArrivalTimeLocal?: string;
	storeDepartureTimeLocal?: string;
	
	doorCloseTimeLocal?: string;
	doorCloseTempZone1?: number;
	doorCloseTempZone2?: number;
	doorCloseTempZone3?: number;

	doorOpenTimeLocal?: string;
	doorOpenTempZone1?: number;
	doorOpenTempZone2?: number;
	doorOpenTempZone3?: number;

	openWindow?: Date;
	closeWindow?: Date;

	constructor(runCustomerOrder?: IRunCustomerOrder) {
		this.deliverySequence = runCustomerOrder?.deliverySequence || 1;
		this.id = runCustomerOrder?.id;
		this.placeId = runCustomerOrder?.placeId?.toString() || '';
		this.placeName = runCustomerOrder?.placeName || '';
		this.storeNumber = runCustomerOrder?.storeNumber || '';
		this.storeMaxPalletCapacity = runCustomerOrder?.storeMaxPalletCapacity;
		this.storeRequiresTailgate = runCustomerOrder?.storeRequiresTailgate;

		this.storeNotificationSentOnLocal = runCustomerOrder?.storeNotificationSentOnLocal;
		this.storeArrivalTimeLocal = runCustomerOrder?.storeArrivalTimeLocal;
		this.storeDepartureTimeLocal = runCustomerOrder?.storeDepartureTimeLocal;

		this.doorCloseTimeLocal = runCustomerOrder?.doorCloseTimeLocal;
		this.doorCloseTempZone1 = runCustomerOrder?.doorCloseTempZone1;
		this.doorCloseTempZone2 = runCustomerOrder?.doorCloseTempZone2;
		this.doorCloseTempZone3 = runCustomerOrder?.doorCloseTempZone3;
 
		this.doorOpenTimeLocal = runCustomerOrder?.doorOpenTimeLocal;
		this.doorOpenTempZone1 = runCustomerOrder?.doorOpenTempZone1;
		this.doorOpenTempZone2 = runCustomerOrder?.doorOpenTempZone2;
		this.doorOpenTempZone3 = runCustomerOrder?.doorOpenTempZone3;

		this.openWindow = runCustomerOrder?.openWindow;
		this.closeWindow = runCustomerOrder?.closeWindow;
	}
}

export class RunCustomerOrderSimple {
	deliverySequence: number;
	placeId = '';
	placeName = '';

	constructor(deliverySequence: number) {
		this.deliverySequence = deliverySequence;
	}
}

export class RunCustomerOrderPOST_PUT {
	id?: number;
	deliverySequence: number;
	placeId: number;

	constructor(runCustomer: RunCustomerOrderForm) {
		this.id = runCustomer.id;
		this.deliverySequence = runCustomer.deliverySequence;
		this.placeId = parseInt(runCustomer.placeId);
	}
}


export interface IRunCustomerOrderOld {
	id?: number;
	deliverySequence: number;
	placeName?: string;
	placeId: string | number;
	placeCategoryId: number | string;
	consignment: IRunCustomerConsignment[];
}

export class RunCustomerOrderClassOld implements IRunCustomerOrderOld {
	deliverySequence = -1;
	placeId = '';
	placeCategoryId = '';
	consignment = [];

	constructor(deliverySequence: number) {
		this.deliverySequence = deliverySequence;
	}
}

export interface IRunCustomerConsignment {
	id?: number;
	commodityId: number | string;
	commodityName?: string;
	pallets: number;
	spaces: number;
	cartons: number;
	isDeleted: boolean;
}

export class RunCustomerConsignment implements IRunCustomerConsignment {
	commodityId = '';
	pallets = 0;
	spaces = 0;
	cartons = 0;
	isDeleted = false;
}

export interface IRunConsignmentDeletion extends IRunCustomerConsignment {
	customerOrderId: number;
	reasonTypeId: number | string;
	reasonName?: string;
	description: string;
}